<!-- Sidebar -->
<div id="sidebar-wrapper">
    <div class="sidebar-logo mb-3"><img src="assets/img/brand-logo.png" alt="Logo"></div>
    <ul class="sidebar-nav">
        <li>
            <a routerLink="/dashboard" [routerLinkActive]="['active-menu']">
                <span class="icon icon-home-o">
                    <img src="assets/img/Home_Line.svg" class="ico-img" alt="">
                    <img src="assets/img/Home_Solid.svg" class="ico-img2" alt="">
                </span>
                <span class="text">Dashboard</span>
            </a>
        </li>
        <li>
            <a routerLink="/order" [routerLinkActive]="['active-menu']">
                <span class="icon icon-order">
                    <img src="assets/img/bag.svg" class="ico-img" alt="">
                    <img src="assets/img/Order_Bag_Solid.svg" class="ico-img2" alt="">
                </span>
                <span class="text">Orders</span>
            </a>
        </li>
        <li>
            <a routerLink="/report" [routerLinkActive]="['active-menu']">
                <span class="icon icon-reports">
                    <img src="assets/img/report.svg" class="ico-img" alt="">
                    <img src="assets/img/Report_Solid.svg" class="ico-img2" alt="">
                </span>
                <span class="text">Reports</span>
            </a>
        </li>
         <li *ngIf="usertype == 'Tier-1'">
            <div class="w3-dropdown-hover">
                <span class="icon icon-creditNote">
                    <img src="assets/img/creditCard.svg" class="ico-img" alt="">
                    <img src="assets/img/creditCard_Solid.svg" class="ico-img2" alt="">
                </span>
                <a >Finance <i class="fa fa-caret-down"></i></a>            
                <div class="w3-dropdown-content w3-bar-block w3-card-4">
                <ul>
                    <li>
                        <a routerLink="/creditNote">                            
                            <span class="text">GST Reveral</span></a>
                    </li>
                    <li>
                        <a routerLink="/tdsDeclare">                        
                        <span class="text">TDS Declaration</span></a>
                    </li>
                </ul>
                </div>
            </div>
        </li>                  
        <li>
            <a routerLink="/tipsAndTools" [routerLinkActive]="['active-menu']">
                <span class="icon icon-tips">
                    <img src="assets/img/Tips%20&%20Tools.svg" class="ico-img" alt="">
                    <img src="assets/img/Tips%20&%20Tools_Solid.svg" class="ico-img2" alt="">
                </span>
                <span class="text">Tips and Tools</span>
            </a>
        </li>
        <li>
            <a routerLink="/announcement" [routerLinkActive]="['active-menu']">
                <span class="icon icon-announcement">
                    <img src="assets/img/Announcement%20(1).svg" class="ico-img" alt="">
                    <img src="assets/img/Announcement%20(2).svg" class="ico-img2" alt="">
                </span>
                <span class="text">Announcement</span>
            </a>
        </li>
        <li>
            <a routerLink="/contact" [routerLinkActive]="['active-menu']">
                <span class="icon icon-contact">
                    <img src="assets/img/Contact%20(1).svg" class="ico-img" alt="">
                    <img src="assets/img/Contact%20(2).svg" class="ico-img2" alt="">
                </span>
                <span class="text">Contact</span>
            </a>
        </li>
    </ul>
    
</div>
<!-- /#sidebar-wrapper -->